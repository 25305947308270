import { markerIcons } from "../resources/markerIcons";

import birthday from '../assets/markers/birthday34.png';
import retornoIcon from '../assets/markers/retorno34.png';
import retornoPremium from '../assets/markers/retornoPremium34.png';
import money34 from '../assets/markers/money34.gif';
import minor from '../assets/markers/menores34.png';

export const markers = {
    getMarkerIcons,
    getMarkerIconsEspecie
}

function getMarkerIcons(item){

    if(item.specialIcon){
        return money34
    }

    if(parseInt(item.idade) < 18){
        return minor
    }

    const hoje = new Date();

    const dia = item.dataNasc.slice(0,2);
    const month = item.dataNasc.slice(3,5);
    const ano = Number(item.dataNasc.slice(-4))+65;

    var alertMonth;
    if(Number(month)-6 < 0){
        alertMonth = 12+(Number(month)-6)
    }else{
        alertMonth = month-6
    }

    const dateAlert = new Date(`${alertMonth}-20-${ano}`);
    const dateBirthday = new Date(`${month}-${dia}-${ano}`);

    if(item.retornos > 0){
        if(item.retornoPremium){
            return retornoPremium
        }else{
            return retornoIcon
        }
    }


    if(hoje.getTime() >= dateAlert.getTime() && hoje.getTime() <= dateBirthday.getTime()){
        return birthday;
    }

    const index = markerIcons.findIndex(x => x.especie === item.especie);

    return markerIcons[index].icon;
}

function getMarkerIconsEspecie(especie){
    const index = markerIcons.findIndex(x => x.especie === especie);

    if(index != -1){
        return markerIcons[index].icon;
    }

    return null
}
