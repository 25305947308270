import React from 'react'

import * as S from './styles';
import { datetime } from '../../../../helpers/datetime';
import { AiOutlineCalendar, AiOutlineEye } from 'react-icons/ai';

import cpfIcon from '../../../../assets/cpfIcon.svg';
import { GoDotFill } from 'react-icons/go';
import { reportResultOptions } from '../../../../constants/reportResultOptions';
import { MdReplay } from 'react-icons/md';

const optionsColors = [
	"var(--success)",
	"var(--success)",
	"var(--red)",
	"var(--red)",
	"var(--red)",
	"var(--yellow)",
	"var(--red)",
	"var(--red)",
	"var(--red)",
]

export default function HistoryListItem({ data, onView, onUndo }) {
	return (
		<S.Container>
			<div>
				<h4>{data.undo ? data.undo.userName : data.userName}</h4>
			</div>
			<div>
				<S.Status undo={data.undo}>
					{data.undo ? "Desfeita" : 'Concluída'}
				</S.Status>
			</div>
			<div>
				<GoDotFill size={10} color={optionsColors[reportResultOptions.findIndex(x => x == data.result)]} />
				<S.TextResult color={optionsColors[reportResultOptions.findIndex(x => x == data.result)]}>
					{data.result}
				</S.TextResult>
			</div>
			<div>
				<h4>{data.description}</h4>
			</div>
			<div>
				<AiOutlineCalendar color='var(--grey2)' />
				<h4>{datetime.getDate(data.time)}</h4>
			</div>
			<div>
				<img src={cpfIcon} alt='cpf' />
				<h4>{data.removedClients}</h4>
			</div>
			<div>
				<img src={cpfIcon} alt='cpf' />
				<h4>{data.notRemovedClients}</h4>
			</div>
			<div>
				<img src={cpfIcon} alt='cpf' />
				<h4>{data.removedClients + data.notRemovedClients}</h4>
			</div>
			<div>
				<AiOutlineEye
					onClick={onView}
					cursor='pointer'
					size={18}
					color='#808B9F'
					fill='#808B9F'
					style={{ marginLeft: 10 }}
				/>
				{!data.undo && (
					<MdReplay
						onClick={onUndo}
						cursor='pointer'
						size={18}
						color='#808B9F'
						fill='#808B9F'
						style={{ marginLeft: 10 }}
					/>
				)}
			</div>
		</S.Container>
	)
}
