import React, { useState, createContext, useEffect } from "react";

import { collection, query, onSnapshot, orderBy, updateDoc, doc, where, getDocs } from "firebase/firestore";
import db from '../firebase/config';
import { USER_TYPE } from "../constants/userType";
import { claims } from "../helpers/claims";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { addClientLogs } from "../firebase/logs/clients/addClientLogs";
import { addMapLogs } from "../firebase/logs/maps/addMapLogs";
import { toast } from "react-toastify";
import { alertSuccessStyle } from "../resources/alertSuccessStyle";
import { alertErrorStyle } from "../resources/alertErrorStyle";
import { arrayHelper } from "../helpers/arrayHelper";
import axios from "axios";

export const MapsContext = createContext();

export const MapsProvider = (props) => {
    const { children } = props;

    const auth = getAuth();

    const [ maps, setMaps ] = useState('');

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const profile = (await user.getIdTokenResult()).claims;

                const role = claims.checkUserRole(profile);

                if(role != USER_TYPE.OPERATOR && role != USER_TYPE.MANAGER){
                    getMapsAdmin()
                }
            }
        });  
    }, [])

    const clearMapsContext = async () => {
        setMaps('');
        return
    }

    const getMapsAdmin = async () => {
        if(!maps){
            const consulta = query(collection(db, 'maps'), orderBy("time"))
            await onSnapshot(consulta, (querySnapshot) => {
                const database = [];
                
                const data = querySnapshot.docs;
                
                for(const i in data){
                    database.push({...data[i].data(), docId: data[i].id});
                }
               
                setMaps(database); 
            }, (error) => {
                console.log("erro get maps: ", error)
            });   
        }          
    } 
    
    const removeFromRoute = async (client) => {

        let index = null
        let route = null

        const routes = []

        const consulta = query(collection(db, 'rotas'), where('status', 'in', ['aguardando', 'iniciado']));
        const querySnapshot = await getDocs(consulta);
        
        querySnapshot.forEach((doc) => {
            routes.push({...doc.data(), docId: doc.id});
        });

        for(const savedRoute of routes){
            const findIndex = savedRoute.points.findIndex(x => x.cpf === client.cpf);

            if(findIndex != -1){
                index = findIndex
                route = savedRoute
            }
        }

        if(index != -1 && route){
            const newPoints = arrayHelper.removeItemOfArray(route.points, index);

            const docRef = doc(db, "rotas", route.docId);

            await updateDoc(docRef, {
                points: newPoints
            })
        }
        
        return
    }

    const removeFromMap = async (client) => {
        return await auth.currentUser.getIdToken(/* forceRefresh */ true).then(async function(idToken) {
            return await axios({
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization:
                        `Bearer ${idToken}`,
                },
                method: 'POST',
                url: `${process.env.REACT_APP_API_URL}/clients/remove-client`,
                data: JSON.stringify({ id: client.docId })
            }).then(() => {
                const logMessage = `${client.nome} foi excluído do mapa`

                toast(logMessage, alertSuccessStyle)
            
                return true
            }).catch((error) => {
                const message = error?.response?.data?.message 
                    ? error?.response?.data?.message 
                    : `Erro ao excluir cliente do mapa: ${error.code}`

                toast(message, alertErrorStyle)
            })
        }).catch((error) => {
            const message = error.message

            const logMessage = `${client.nome} Erro ao excluir cliente do mapa: ${message}`

            toast(logMessage, alertErrorStyle)
        }); 
    }

    return (
        <MapsContext.Provider 
            value={{ 
                maps: maps,
                getMapsAdmin,
                clearMapsContext,
                removeFromMap,
                removeFromRoute
            }}
        >
            {children}
        </MapsContext.Provider>
    )
}