import React, { useEffect, useState, useContext, useRef } from 'react';

import {
    Container,
    Table,
    UploadButton,
    DownloadButton,
    Header,
    UploadContent,
    UploadSide,
    ModelSide,
    TableColumns,
    TableList,
    TableItem,
    ActionsContent,
    CancelButton,
    ConfirmButton,
    InputContainer,
    Input,
    HistoryContent,
    Content,
    TableHeader,
    TextInput,
} from './styles';

//Firebase and context
import { getStorage, ref, getDownloadURL } from "firebase/storage";

//API
import Geocode from "react-geocode";

//Custom components
import ProfileButton from '../../components/ProfileButton';
import CustomLoadingPage from '../../components/CustomLoadingPage';

//Icons
import importIcon from '../../assets/importIcon.svg'
import importIconActive from '../../assets/importIconActive.svg'
import { FiDownload } from 'react-icons/fi';
import cpfIcon from '../../assets/cpfIcon.svg'

//Utils
import { toast } from 'react-toastify';
import { alertErrorStyle } from '../../resources/alertErrorStyle'
import { alertSuccessStyle } from '../../resources/alertSuccessStyle'
import { csvHelper } from '../../helpers/csvHelper';
import { arrayHelper } from '../../helpers/arrayHelper';
import { AuthContext } from '../../contexts/AuthContext';
import { MapsContext } from '../../contexts/MapsContext';
import useRemove from '../../hooks/useRemove';
import Select from 'react-select';
import { ClipLoader } from 'react-spinners';
import HistoryList from './components/HistoryList';
import useMapClients from '../../hooks/useMapClients';

export const resultOptions = [
    { label: "Contrato fechado", value: "Contrato fechado" },
    { label: "Já tem advogado", value: "Já tem advogado" },
    { label: "Não tem direito", value: "Não tem direito" },
    { label: "Não tem interesse", value: "Não tem interesse" },
]

const dateNow = new Date();

const RemovePage = () => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage('pt-BR');

    const { userData } = useContext(AuthContext);

    const { removeHistory, removeClientsFromMap } = useRemove();

    const { mapClients } = useMapClients();
    const { maps, getMapsAdmin } = useContext(MapsContext);

    const inputRef = useRef();

    const [loadingData, setLoadingData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [removeData, setRemoveData] = useState('');
    const [inputValue, setInputValue] = useState('');

    const [selectedResult, setSelectedResult] = useState('');
    const [description, setDescription] = useState('');

    const [processedData, setProcessedData] = useState('')

    useEffect(() => {
        (async () => {
            if (mapClients && maps) {
                setLoadingData(false);
            } else {
                await getMapsAdmin();
            }
        })();
    }, [mapClients, maps])

    const clearOperation = () => {
        setInputValue('');
        setRemoveData('')
        setProcessedData('')
    }

    const readCsvFile = (csvFile) => {
        const reader = new FileReader();

        setLoading(true)

        reader.onload = (e) => {
            const text = e.target.result;

            const splitFile = text.split('\r\n');
            const [header, ...files] = splitFile;

            const headerSplit = header.split(';');

            const { valid, invalidFields } = csvHelper.validateRemoveHeader(headerSplit);

            if (!valid) {
                return toast(`A planilha selecionada não possui as colunas obrigatórias: ${arrayHelper.arrayToString(invalidFields, ', ')
                    }`, alertErrorStyle)
            }

            const { array, errors } = csvHelper.processRemoveCsv(files, headerSplit)

            if (errors.length == 0) {
                // this.props.setData(selectedOption, array);
                processData(array)
            } else {
                for (const errorMessage of errors) {
                    toast(errorMessage, alertErrorStyle)
                }
            }

        }

        reader.readAsText(csvFile);
    }

    const processData = (data) => {
        const arrayNotExists = []
        const arrayExists = []

        for (const client of data) {
            const result = mapClients.find(x => x.cpf === client.cpf);

            if (result) {
                arrayExists.push({
                    cpf: client.cpf,
                    nome: client.nome,
                    processos: client.processos,
                    mapId: result.mapId ? result.mapId : '',
                    cidadeMap: result.cidadeMap ? result.cidadeMap : ''
                })
            } else {
                arrayNotExists.push({
                    cpf: client.cpf,
                    nome: client.nome,
                    processos: client.processos,
                    mapId: '',
                    cidadeMap: ''
                })
            }
        }

        setProcessedData({
            exists: arrayExists,
            notExists: arrayNotExists,
        })

        setRemoveData(arrayExists)

        setLoading(false)
    }

    const handleRemove = async () => {
        var mapsClientsQtd = [];

        setLoading(true);

        if (removeData.length > 0) {
            for (const client of removeData) {
                const result = mapClients.find(x => x.cpf === client.cpf);

                if (result && result.mapId) {
                    for (const map of maps) {
                        for (const city of map.cities) {
                            if (city.value == result.cidadeMap) {
                                result['mapId'] = map.docId
                                const index = mapsClientsQtd.findIndex(x => x.mapId === map.docId);

                                if (index == -1) {
                                    mapsClientsQtd.push({
                                        mapId: map.docId,
                                        mapName: map.mapName,
                                        qtd: 1
                                    })
                                } else {
                                    const array1 = mapsClientsQtd.slice(0, index);
                                    const array2 = mapsClientsQtd.slice(index + 1, mapsClientsQtd.length)

                                    array1.push({
                                        mapId: map.docId,
                                        mapName: map.mapName,
                                        qtd: mapsClientsQtd[index].qtd + 1
                                    })
                                    mapsClientsQtd = array1.concat(array2);
                                }
                            }
                        }
                    }
                }
            }

            if (selectedResult && description && userData.userName) {
                const { success, message } = await removeClientsFromMap({
                    userName: userData.userName,
                    mapsClientsQtd: mapsClientsQtd,
                    clientsInMap: removeData,
                    clientsNotInMap: processedData.notExists,
                    result: selectedResult?.value,
                    description: description,
                })

                if (success) {
                    if (processedData && processedData.notExists?.length != 0) {
                        toast(`${processedData.notExists.length} clientes não removidos, pois não estão no mapa.`, alertErrorStyle)
                    }

                    toast(`${removeData.length} clientes foram removidos do mapa com sucesso`, alertSuccessStyle)
                    clearOperation()
                } else {
                    toast(`Erro ao remover clientes do mapa: ${message}`, alertErrorStyle)
                    clearOperation()
                }
            } else {
                toast(`Selecione um resultado para prosseguir`, alertErrorStyle)
            }
        } else {
            toast('Nenhum cliente a ser removido do mapa', alertErrorStyle)
            clearOperation()
        }

        setLoading(false);
        // this.fileInput.value = "";
    }

    const downloadModelos = async (name) => {
        const storage = getStorage();
        const pathReference = ref(storage, `gs://central-juridica.appspot.com/${name}.csv`);

        getDownloadURL(pathReference)
            .then((url) => {
                // `url` is the download URL for 'images/stars.jpg'
                window.open(url, '_blank', 'noopener,noreferrer');
            })
            .catch((error) => {
                alert('Ocorreu um erro');
            });
    }

    if (loadingData) {
        return <Container>
            <CustomLoadingPage />
        </Container>
    }

    const customStyle = {
        container: (base) => ({
            ...base,
            borderRadius: 40,
            marginBottom: 10
        }),
        control: (base) => ({
            ...base,
            padding: 3,
            borderRadius: 40
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40,
            backgroundColor: '#ECF2FB'
        }),
    }

    return (
        <Container>
            <Header>
                <h1>Remover clientes do mapa</h1>
                <ProfileButton arrowColor='var(grey2)' />
            </Header>

            <Content>
                <UploadContent>
                    <UploadSide>
                        <h3>Os clientes serão removidos do mapa através de um atendimento</h3>

                        <h4>Selectione um resultado para o atendimento</h4>
                        <Select
                            id='select-result'
                            options={resultOptions}
                            defaultValue={selectedResult ? selectedResult : null}
                            styles={customStyle}
                            onChange={(selectedOption) => {
                                setSelectedResult(selectedOption)
                            }}
                        />

                        <h4>Descrição</h4>
                        <TextInput>
                            <input
                                name="description"
                                onChange={event => setDescription(event.target.value)}
                                placeholder='Descrição do atendimento'
                            />
                        </TextInput>

                        <InputContainer>
                            <div>
                                <img src={removeData ? importIconActive : importIcon} alt='img img' />
                                <h3>{removeData ? 'Arquivo selecionado' : 'Escolher arquivo...'}</h3>
                            </div>

                            <Input
                                type='file'
                                accept='.csv'
                                id='csvfile'
                                ref={inputRef}
                                placeholder=''
                                value={inputValue}
                                onChange={async (e) => {
                                    setInputValue(e.target.value)
                                    readCsvFile(e.target.files[0])
                                }}
                            />


                        </InputContainer>
                    </UploadSide>
                    <ModelSide>
                        <DownloadButton onClick={async () => downloadModelos('remocao')}>
                            <FiDownload />
                            Modelo Remoção do Mapa
                        </DownloadButton>
                    </ModelSide>

                </UploadContent>
                {removeData && (
                    <Table>
                        <TableHeader>
                            {removeData.length > 0
                                ? <>
                                    <h1>
                                        {removeData.length} clientes serão removidos do mapa
                                    </h1>
                                    {processedData && processedData.notExists?.length > 0 && (
                                        <h3>({processedData.notExists.length} clientes não estão no mapa)</h3>
                                    )}
                                </>
                                : <h1>
                                    Nenhum cliente selecionado está no mapa
                                </h1>
                            }
                        </TableHeader>

                        {removeData && removeData.length > 0 && (
                            <>
                                <TableColumns>
                                    <h3>CPF</h3>
                                    <h3>Nome</h3>
                                    <h3>Processos</h3>
                                </TableColumns>
                                <TableList>
                                    {removeData.map((item, index) => (
                                        <TableItem key={index}>
                                            <div>
                                                <img src={cpfIcon} alt='img img' />
                                                <h3>{item.cpf}</h3>
                                            </div>
                                            <h1>{item.nome}</h1>
                                            <h1>{item.processos}</h1>
                                        </TableItem>
                                    ))}
                                </TableList>
                            </>
                        )}
                        {selectedResult && description 
                            ? (
                                <ActionsContent>
                                    {loading
                                        ? <ClipLoader color='var(--red)' />
                                        : <>
                                            <CancelButton onClick={() => clearOperation()}>
                                                <p>Cancelar remoção</p>
                                            </CancelButton>
                                            {removeData.length > 0 && (
                                                <ConfirmButton onClick={(e) => {
                                                    e.preventDefault();
                                                    handleRemove();
                                                }}>
                                                    <p>Remover do mapa</p>
                                                </ConfirmButton>
                                            )}
                                        </>
                                    }
                                </ActionsContent>
                            )
                            : <ActionsContent>
                                {removeData && removeData.length > 0
                                    ? <h3>Selecione o resultado e descreva o atendimento para continuar</h3>
                                    :  <CancelButton onClick={() => clearOperation()}>
                                        <p>Cancelar remoção</p>
                                    </CancelButton>
                                }
                            </ActionsContent>
                        }
                    </Table>
                )}
            </Content>

            <HistoryContent>
                <HistoryList 
                    removeHistory={removeHistory}
                />
            </HistoryContent>
        </Container>

    )
};

export default RemovePage;